import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { BaseComponent } from '../../../base/components/base-component';

@Component({
  selector: 'intello-form-deactivateable-dialog',
  templateUrl: './form-deactivateable-dialog.component.html'
})
export class FormDeactivateableDialogComponent extends BaseComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<FormDeactivateableDialogComponent>,
  ) {
    super();
  }
}
