import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'projects/cms/src/environments/environment';
import { Observable } from 'rxjs';

/**
 * Prefixes all requests with `environment.serverUrl`.
 */
@Injectable()
export class ApiPrefixInterceptor implements HttpInterceptor {
  // -----------------------------------------------------------------------------------------------------
  // @ INTERFACES
  // -----------------------------------------------------------------------------------------------------

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.startsWith(environment.idpServerUrl) || request.url.startsWith('http://sendable')) {
      return next.handle(request);
    }
    request = request.clone({ url: `${environment.cmsServerUrl}v1/${request.url}` });
    return next.handle(request);
  }
}
