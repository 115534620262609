import { CurrencyPipe, registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import localeEn from '@angular/common/locales/en';
import { LOCALE_ID, NgModule } from '@angular/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter, MomentDateModule } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateModule } from '@ngx-translate/core';
import { OAuthModule } from 'angular-oauth2-oidc';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { ToastrModule } from 'ngx-toastr';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { I18nService } from './core/i18n.service';
import { AppRoute } from './shared/app.route.enum';
import {
  ConfigurableTableDialogModule,
} from '../../../shared/src/lib/shared/components/configurable-table-dialog/configurable-table-dialog.module';
import { environment } from '../environments/environment';
import { SharedModule } from 'projects/shared/src/lib/shared';

registerLocaleData(localeDe);
registerLocaleData(localeEn);

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD.MM.YYYY'
  },
  display: {
    dateInput: 'DD.MM.YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  }
};

const maskConfig: Partial<IConfig> = {
  validation: true,
};

export function getCurrentLanguage(i18nService: I18nService) {
  i18nService.init(environment.supportedLanguages);
  return i18nService.language;
}

@NgModule({
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    NgxMaskModule.forRoot(maskConfig),
    HttpClientModule,

    CoreModule,
    SharedModule,
    TranslateModule.forRoot(),
    OAuthModule.forRoot(),

    MomentDateModule,
    MatDatepickerModule,
    ConfigurableTableDialogModule,

    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-top-right',
      preventDuplicates: true
    }),

    AppRoutingModule // must be imported as the last module as it contains the fallback route
  ],
  declarations: [AppComponent],
  providers: [
    CurrencyPipe,
    { provide: LOCALE_ID, useFactory: getCurrentLanguage, deps: [I18nService] },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {

  constructor() {
    // https://patorjk.com/software/taag/#p=display&f=Epic&t=Sendable
    console.log(`
_________ _       _________ _______  _        _        _______ 
\\__   __/( (    /|\\__   __/(  ____ \\( \\      ( \\      (  ___  )
   ) (   |  \\  ( |   ) (   | (    \\/| (      | (      | (   ) |
   | |   |   \\ | |   | |   | (__    | |      | |      | |   | |
   | |   | (\\ \\) |   | |   |  __)   | |      | |      | |   | |
   | |   | | \\   |   | |   | (      | |      | |      | |   | |
___) (___| )  \\  |   | |   | (____/\\| (____/\\| (____/\\| (___) |
\\_______/|/    )_)   )_(   (_______/(_______/(_______/(_______)

    `);
    console.log('Hi! Nice to meet you. Interested in any developer related product, such as our api? Contact us at info@intello.com!');
    console.log(' ');
  }
}
