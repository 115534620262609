import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonsMenuModule } from 'projects/shared/src/lib/shared/components/buttons/menu/buttons-menu.module';
import {
  JadFormFieldsSelectModule,
} from 'projects/shared/src/lib/shared/components/form-fields/select/form-fields-select.module';
import { ImageModule } from 'projects/shared/src/lib/shared/components/image/image.module';
import { SearchModule } from 'projects/shared/src/lib/shared/components/search';

import { ContentHeaderComponent } from './content-header.component';

@NgModule({
  imports: [
    CommonModule,

    ImageModule,
    RouterModule,
    TranslateModule,
    MatMenuModule,
    MatSelectModule,
    ButtonsMenuModule,
    MatIconModule,
    MatBadgeModule,
    SearchModule,

    JadFormFieldsSelectModule
  ],
  declarations: [
    ContentHeaderComponent
  ],
  exports: [ContentHeaderComponent]
})
export class ContentHeaderModule { }
