import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { Logger } from 'projects/shared/src/lib/shared/core/logger.service';
import { merge } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';

import { BaseComponent } from '../../../shared/src/lib/shared/base/components/base-component';
import { ForbiddenService } from '../../../shared/src/lib/shared/services/local/forbidden.service';
import { RedirectService } from '../../../shared/src/lib/shared/services/redirect.service';
import { UsersService } from '../../../shared/src/lib/shared/services/users.service';
import { environment } from '../environments/environment';
import { AuthenticationService } from './core/authentication/authentication.service';
import { I18nService } from './core/i18n.service';
import { __ } from 'projects/shared/src/lib/shared/functions/object.functions';

const log = new Logger('App');

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent extends BaseComponent implements OnInit {

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private forbiddenService: ForbiddenService,
    private i18nService: I18nService,
    private translateService: TranslateService,
    private usersService: UsersService,
    private dialog: MatDialog,
    private redirectService: RedirectService,
    private authenticationService: AuthenticationService,
    private oAuthService: OAuthService
  ) {
    super();
  }

  async ngOnInit() {

    if (environment.production) {
      Logger.enableProductionMode();
    }


    this.i18nService.init(environment.supportedLanguages);

    const onNavigationEnd = this.router.events.pipe(filter(event => event instanceof NavigationEnd));

    const onNavigationStart = this.router.events.pipe(filter(event => event instanceof NavigationStart));

    // Change page title on navigation or language change, based on route data
    merge(onNavigationStart)
      .subscribe(event => {
        this.forbiddenService.allow();
      });

    // Change page title on navigation or language change, based on route data
    merge(onNavigationEnd)
      .pipe(
        map(() => {
          let route = this.activatedRoute;
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter(route => route.outlet === 'primary'),
        mergeMap(route => route.data)
      )
      .subscribe(event => {
        const title = event['title'];
        if (title) {
          this.titleService.setTitle(this.translateService.instant(title));
        }
      });
  }
}
