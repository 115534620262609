import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { RouterModule } from '@angular/router';
import { SharedModule } from 'projects/shared/src/lib/shared';
import { SidebarService } from 'projects/shared/src/lib/shared/services/local/side-bar.service';

import { ActionsModule } from './actions/actions.module';
import { BaseLayoutComponent } from './base-layout.component';
import { ContentHeaderModule } from './content-header/content-header.module';
import { NavigationModule } from './navigation/navigation.module';
import { SideBarModule } from './side-bar/side-bar.module';
import { UserInfoModule } from './user-info/user-info.module';

@NgModule({
    imports: [
        SharedModule,

        UserInfoModule,
        ActionsModule,
        NavigationModule,
        ContentHeaderModule,
        MatIconModule,
        SideBarModule,

        MatSelectModule,

        RouterModule
    ],
    declarations: [
        BaseLayoutComponent
    ],
    providers: [SidebarService]
})
export class BaseLayoutModule { }