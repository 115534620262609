<div class="content-header flex flex-wrap justify-between items-center">

  <!-- HEADER START -->
  <div
    [ngClass]="{'flex justify-center items-center flex-wrap': true }">
  </div>

  <!-- HEADER END -->
  <div class="header-end">

    <!-- VOLUME SLIDER -->
    <div class="mr-12" style="z-index: 3;">
    </div>

    <div class="flex">
      <!-- NAVIGATION -->
      <intello-buttons-menu [sizeClass]="'medium'"  style="z-index: 3;">
        <ng-template kbButtonsMenuLabel>
          <mat-icon [ngClass]="['large']" [svgIcon]="'ellipsis'"></mat-icon>
        </ng-template>

        <!-- LICENSES-->
        <intello-buttons-menu-action (onClick)="router.navigate(['/licenses'])" [order]="'icon-last'" [iconClass]="'menu'"
          displayText="{{ 'Navigation.Licenses' | translate }}" colorClass="default" sizeClass="large" icon="info">
        </intello-buttons-menu-action>

        <!-- HELP SUPPORT -->
        <intello-buttons-menu-action (onClick)="router.navigate(['/help'])" [order]="'icon-last'" [iconClass]="'menu'"
          displayText="{{ 'Navigation.Help / Support' | translate }}" colorClass="default" sizeClass="large" icon="help">
        </intello-buttons-menu-action>

        <!-- WORK -->
        <intello-buttons-menu-action (onClick)="router.navigate(['/work'])" [order]="'icon-last'" [iconClass]="'menu'"
          displayText="{{ 'Navigation.Work' | translate }}" colorClass="default" sizeClass="large" icon="work">
        </intello-buttons-menu-action>

        <!-- SHARE -->
        <!-- <intello-buttons-menu-action (onClick)="setLanguage($event)" [order]="'icon-last'" [iconClass]="'menu'"
        [displayText]="'Share'" colorClass="default" sizeClass="large" icon="share">
      </intello-buttons-menu-action> -->
      </intello-buttons-menu>

      <!-- MOBILE UPLOAD -->

      <!-- LANGUAGE -->
      <intello-buttons-menu [sizeClass]="'medium'" style="z-index: 3;">
        <ng-template kbButtonsMenuLabel>
          <mat-icon [ngClass]="['large']" [svgIcon]="'language'"></mat-icon>
        </ng-template>
        <ng-container *ngFor="let language of Languages">
          <intello-buttons-menu-action (onClick)="setLanguage(language.value)" [order]="'icon-last'"
            [displayText]="language.displayName" colorClass="default" sizeClass="large" icon="arrow_left">
          </intello-buttons-menu-action>
        </ng-container>
      </intello-buttons-menu>

      <!-- ACCOUNT -->
      <intello-buttons-menu [sizeClass]="'medium'" style="z-index: 3;">
        <ng-template kbButtonsMenuLabel>
          <mat-icon [ngClass]="['large']" [svgIcon]="'user'"></mat-icon>
        </ng-template>

        <!-- LOGIN -->
        <intello-buttons-menu-action *ngIf="authenticationService.isAuthenticated() === false"
          (onClick)="router.navigate(['/login'])" [order]="'icon-last'" [iconClass]="'menu'" displayText="{{ 'Navigation.Login' | translate }}"
          colorClass="default" sizeClass="large" icon="sign_in">
        </intello-buttons-menu-action>

        <!-- LOGOUT -->
        <intello-buttons-menu-action *ngIf="authenticationService.isAuthenticated() === true" (onClick)="logout()"
          [order]="'icon-last'" [iconClass]="'menu'" displayText="{{ 'Navigation.Logout' | translate }}" colorClass="default" sizeClass="large"
          icon="logout">
        </intello-buttons-menu-action>

        <!-- MY ACCOUNT -->
        <intello-buttons-menu-action *ngIf="authenticationService.isAuthenticated() === true"
          (onClick)="router.navigate(['/my-account'])" [order]="'icon-last'" [iconClass]="'menu'"
          displayText="{{ 'Navigation.My Account' | translate }}" colorClass="default" sizeClass="large" icon="user">
        </intello-buttons-menu-action>

        <!-- ADMIN -->
        <intello-buttons-menu-action
          *ngIf="authenticationService.isAuthenticated() === true && authenticationService.hasAnyRole(['Administrator', 'CompanyAdministrator'])"
          (onClick)="router.navigate(['/admin'])" [order]="'icon-last'" [iconClass]="'menu'"
          displayText="{{ 'Navigation.Admin Dashboard' | translate }}" colorClass="default" sizeClass="large" icon="work">
        </intello-buttons-menu-action>
      </intello-buttons-menu>

    </div>
  </div>

</div>