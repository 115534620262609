import { Component } from '@angular/core';
import { SidebarService } from 'projects/shared/src/lib/shared/services/local/side-bar.service';

import { BaseSideBarComponent } from '../shared/base-side-bar-component';

@Component({
  selector: 'intello-side-bar-actions',
  templateUrl: './side-bar-actions.component.html',
  styleUrls: ['./side-bar-actions.component.scss'],
})
export class SideBarActionsComponent extends BaseSideBarComponent {

  // -----------------------------------------------------------------------------------------------------
  // @ CONSTRUCTOR
  // -----------------------------------------------------------------------------------------------------

  constructor(
    protected sideBarService: SidebarService
  ) {
    super(sideBarService);
  }

}
