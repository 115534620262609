import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { ToastrService } from 'ngx-toastr';
import { __ } from 'projects/shared/src/lib/shared/functions/object.functions';
import { RedirectService } from 'projects/shared/src/lib/shared/services/redirect.service';
import { Observable, from, of, switchMap } from 'rxjs';

import { AppRoute } from '../../shared/app.route.enum';
import { AuthenticationService } from './authentication.service';
import { RouteService } from 'projects/shared/src/lib/shared/core/route.service';

@Injectable()
export class AuthenticationGuard implements CanActivate {

  // -----------------------------------------------------------------------------------------------------
  // @ CONSTRUCTOR
  // -----------------------------------------------------------------------------------------------------

  constructor(
    private router: Router,
    private oAuthService: OAuthService,
    private authenticationService: AuthenticationService,
    private redirectService: RedirectService,
    private toastr: ToastrService,
    private translateService: TranslateService
  ) { }

  // -----------------------------------------------------------------------------------------------------
  // @ INTERFACES
  // -----------------------------------------------------------------------------------------------------

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    const isLoginCallback = location.href.includes('code=');

    // Set the redirect url if the access token is invalid, since it will then
    // be refreshed
    if (this.oAuthService.hasValidAccessToken() === false) {
      this.redirectService.setRedirectUri(window.location.pathname);
    }

    return from(this.authenticationService.checkLoginStatusOrLogin())
      .pipe(
        switchMap((shouldDisplayApplication: boolean) => {
          // Disallow access when we are in a login flow
          if (shouldDisplayApplication === false) {
            return of(false);
          }

          // Check privileges
          const data = RouteService.MergeData(route);

          // If there roles present in the route configuration, and the currently logged in user
          // does not fit the role, check, if we should route to the forbidden page
          if (!__.IsNullOrUndefined(data['roles']) && !this.authenticationService.hasAnyRole(data['roles'])) {
            return of(this.checkForbidden(route));
          }

          // If a redirect uri is present and we are in the login flow, redirect to the specific page,
          // since the route state was lost during external login redirection
          if (!__.IsNullOrUndefinedOrEmpty(this.redirectService.redirectUri) && isLoginCallback) {
            const redirectUri = this.redirectService.redirectUri;

            if (this.redirectService.redirectUri !== '/') {
              this.redirectService.reset();
            }

            this.router.navigateByUrl(redirectUri);
          }

          // All good, the user is allowed to pass
          return of(true);
        })
      );
  }

  // -----------------------------------------------------------------------------------------------------
  // @ PRIVATE METHODS
  // -----------------------------------------------------------------------------------------------------

  private checkForbidden(route: ActivatedRouteSnapshot): boolean {
    // TODO: POTENTIALLY BREAKING
    if (route['_routerState'].url === '/' || route['_routerState'].url.includes(AppRoute.Home)) {
      return true;
    }
    this.toastr.error(this.translateService.instant('General.You are not allowed to view this page. Please ask your administrator for permissions to view this page'));
    this.router.navigate([`/${AppRoute.Home}`], { replaceUrl: true });

    return false;
  }
}
