import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable, Subscriber } from 'rxjs';

import { HttpCacheService } from './http-cache.service';
import moment from 'moment';
import { __ } from '../../functions/object.functions';

export class CacheSettings {
  // -----------------------------------------------------------------------------------------------------
  // @ PUBLIC INSTANCE VARIABLES
  // -----------------------------------------------------------------------------------------------------

  lastUpdated?: Date;
  expires?: number | Date; // seconds | date
  update?: boolean;

  // -----------------------------------------------------------------------------------------------------
  // @ CONSTRUCTOR
  // -----------------------------------------------------------------------------------------------------

  constructor(expires?: number | Date) {
    this.expires = expires;
  }

}

/**
 * Caches HTTP requests.
 * Use ExtendedHttpClient fluent API to configure caching for each request.
 */
@Injectable()
export class CacheInterceptor implements HttpInterceptor {

  // -----------------------------------------------------------------------------------------------------
  // @ PRIVATE INSTANCE VARIABLES
  // -----------------------------------------------------------------------------------------------------

  private settings: CacheSettings;

  private forceUpdate = false;

  // -----------------------------------------------------------------------------------------------------
  // @ CONSTRUCTOR
  // -----------------------------------------------------------------------------------------------------

  constructor(private httpCacheService: HttpCacheService) {
    httpCacheService.setPersistence('local');
  }

  // -----------------------------------------------------------------------------------------------------
  // @ PUBLIC METHODS
  // -----------------------------------------------------------------------------------------------------

  /**
   * Configures interceptor options
   * @param options If update option is enabled, forces request to be made and updates cache entry.
   * @return The configured instance.
   */
  configure(options?: CacheSettings | null): CacheInterceptor {
    const instance = new CacheInterceptor(this.httpCacheService);
    if (!__.IsNullOrUndefined(options)) {
      if (!__.IsNullOrUndefined(options.expires)) {
        options.expires = isNaN(options.expires as any) ? options.expires : moment().add(options.expires as number, 'seconds').toDate();
      }
      instance.settings = options;
      instance.forceUpdate = !__.IsNullOrUndefined(options.update) ? options.update : false;
    }
    return instance;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ INTERFACES
  // -----------------------------------------------------------------------------------------------------

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.method !== 'GET') {
      return next.handle(request);
    }

    return new Observable((subscriber: Subscriber<HttpEvent<any>>) => {
      const cachedData = this.forceUpdate ? null : this.httpCacheService.getCacheData(request.urlWithParams);
      if (cachedData !== null) {
        // Create new response to avoid side-effects
        subscriber.next(new HttpResponse(cachedData));
        subscriber.complete();
      } else {
        next.handle(request).subscribe(
          event => {
            if (event instanceof HttpResponse) {
              this.httpCacheService.setCacheData(request.urlWithParams, event, this.settings?.lastUpdated, this.settings?.expires as Date);
            }
            subscriber.next(event);
          },
          error => subscriber.error(error),
          () => subscriber.complete()
        );
      }
    });
  }
}
