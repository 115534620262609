import { Injectable } from '@angular/core';

import { BaseService } from '../base/services';
import { __ } from '../functions/object.functions';

const key = `intello_redirect_uri_qzweuiaijsdoasdasd`;

@Injectable()
export class RedirectService extends BaseService {

    private _redirectUri: string;

    get redirectUri(): string {
        return this._redirectUri ?? localStorage.getItem(key);
    }
    set redirectUri(value: string) {
        this._redirectUri = value;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ CONSTRUCTOR
    // -----------------------------------------------------------------------------------------------------

    constructor() {
        super();
    }

    setRedirectUri(redirectUri: string): void {
        // Only if no redirect uri is currently present we can set a new one
        // TODO:
        if (__.IsNullOrUndefinedOrEmpty(this.redirectUri) || this.redirectUri === '/') {
            localStorage.setItem(key, redirectUri);
            this.redirectUri = redirectUri;
        }
    }

    reset(): void {
        this.redirectUri = null;
        localStorage.removeItem(key);
    }
}
