import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { BaseService } from '../../base/services';

@Injectable()
export class SidebarService extends BaseService {

    // -----------------------------------------------------------------------------------------------------
    // @ PUBLIC INSTANCE VARIABLES
    // -----------------------------------------------------------------------------------------------------

    isExpanded: boolean = true;

    // -----------------------------------------------------------------------------------------------------
    // @ PRIVATE INSTANCE VARIABLES
    // -----------------------------------------------------------------------------------------------------

    private _expand$: Subject<boolean> = new Subject<boolean>();

    // -----------------------------------------------------------------------------------------------------
    // @ PUBLIC PRIVATE DEPENDENT INSTANCE VARIABLES
    // -----------------------------------------------------------------------------------------------------

    // tslint:disable-next-line:member-ordering
    public expand$: Observable<boolean> = this._expand$.asObservable();
    // tslint:disable-next-line:member-ordering

    // -----------------------------------------------------------------------------------------------------
    // @ CONSTRUCTOR
    // -----------------------------------------------------------------------------------------------------

    constructor() {
        super();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ PUBLIC METHODS
    // -----------------------------------------------------------------------------------------------------

    toggleSideBar(isExpanded: boolean): void {
        this.isExpanded = isExpanded;
        this._expand$.next(isExpanded);
    }

}
