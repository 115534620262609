<!-- HOME -->
<intello-navigation-link link="home" icon="home" [displayName]="'Home.Home' | translate">
</intello-navigation-link>

<!-- BROWSE -->
<intello-navigation-link link="my-partners" icon="browse" [displayName]="'Home.My partners' | translate">
</intello-navigation-link>

<!-- DISCOVER -->
<intello-navigation-link link="orders" icon="discover" [displayName]="'Home.Orders' | translate">
</intello-navigation-link>

<!-- MY PROJECTS -->
<intello-navigation-link link="settings" icon="my_projects" [displayName]="'Home.Settings' | translate">
</intello-navigation-link>

<!-- FAVOURITES -->
<intello-navigation-link link="support" icon="favorite" [displayName]="'Home.Support' | translate">
</intello-navigation-link>

<!-- HISTORY -->
<intello-navigation-link link="faqs" icon="history" [displayName]="'Home.FAQs' | translate">
</intello-navigation-link>