<!-- SIDE BAR -->
<div class="hidden md:flex sidebar__container flex-row h-screen max-h-screen top-0">
  <div class="sidebar flex-initial flex-col h-screen max-h-screen bg-secondary-black"
    [ngClass]="{ 'sidebar--expanded': isExpanded === true}">

    <!-- HEADER -->
    <div class="sidebar__header flex-initial flex flex-row px-3 pt-12 pb-7 justify-center">
      <intello-side-bar-header></intello-side-bar-header>
    </div>

    <!-- NAVIGATION -->
    <div class="flex-initial">
      <intello-side-bar-navigation></intello-side-bar-navigation>
    </div>

    <!-- ACTIONS -->
    <div class="flex-initial">
      <intello-side-bar-actions></intello-side-bar-actions>
    </div>

  </div>
</div>

<div class="block fixed md:hidden bottom-0 left-0 right-0" style="z-index: 1000;">

  <div class="mobile-menu">
    <intello-side-bar-navigation></intello-side-bar-navigation>
  </div>

</div>