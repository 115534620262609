import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { Route } from './core/Routes';
import { AppRoute } from './shared/app.route.enum';

const routes: Routes = [
  // The base layout and authentication takes care of authentication
  // for every request. Use the authentication guard on lower routing levels
  // to enable permission based authorization (see below)
  {
    path: '',
    redirectTo: `/${AppRoute.Languages}`,
    pathMatch: 'full'
  },
  Route.withBaseLayoutAndAuthentication([
    {
      path: AppRoute.Forbidden,
      loadChildren: () => import('./core/forbidden/forbidden.module').then(m => m.ForbiddenModule)
    },
    {
      path: AppRoute.Languages,
      loadChildren: () => import('./languages/languages.module').then(m => m.LanguagesModule)
    },
    {
      path: AppRoute.Courses,
      loadChildren: () => import('./courses/courses.module').then(m => m.CoursesModule)
    },
    {
      path: AppRoute.Modules,
      loadChildren: () => import('./modules/modules.module').then(m => m.ModulesModule)
    },
    {
      path: AppRoute.Lessons,
      loadChildren: () => import('./lessons/lessons.module').then(m => m.LessonsModule)
    },
    {
      path: AppRoute.Files,
      loadChildren: () => import('./files/files.module').then(m => m.FilesModule)
    },
    {
      path: AppRoute.Groups,
      loadChildren: () => import('./groups/groups.module').then(m => m.GroupsModule)
    },
    {
      path: AppRoute.ContentElements,
      loadChildren: () => import('./content-elements/content-elements.module').then(m => m.ContentElementsModule)
    },
    {
      path: AppRoute.Ideas,
      loadChildren: () => import('./ideas/ideas.module').then(m => m.IdeasModule)
    },
    {
      path: AppRoute.Users,
      loadChildren: () => import('./users/users.module').then(m => m.UsersModule)
    },
    {
      path: AppRoute.Account,
      loadChildren: () => import('./account/account.module').then(m => m.AccountModule)
    }
  ]),
  { path: '**', redirectTo: `/${AppRoute.Languages}` }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    enableTracing: false
  })],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule { }
