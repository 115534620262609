<!-- ACTIONS -->
<div [ngClass]="{'py-5 align-center': true, 'justify-center': !isExpanded}">

    <!-- EXPAND -->
    <ng-container *ngIf="isExpanded; else collapsed;">
        <intello-navigation-link iconClass="enabled" icon="arrow_left" justifyPosition="start" (click)="toggleSideBar()">
        </intello-navigation-link>
    </ng-container>
    
    <!-- COLLAPSED -->
    <ng-template #collapsed>
        <intello-navigation-link iconClass="enabled" icon="arrow_right" (click)="toggleSideBar()" >
        </intello-navigation-link>
    </ng-template>
    
</div>