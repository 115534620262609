import { Directive } from '@angular/core';
import { BaseComponent } from 'projects/shared/src/lib/shared/base/components/base-component';
import { SidebarService } from 'projects/shared/src/lib/shared/services/local/side-bar.service';

/**
 * This class contains side bar specific properties 
 *
 * @export
 */
@Directive()
export abstract class BaseSideBarComponent extends BaseComponent {

  // -----------------------------------------------------------------------------------------------------
  // @ PUBLIC INSTANCE VARIABLES
  // -----------------------------------------------------------------------------------------------------

  isExpanded: boolean = true;

  // -----------------------------------------------------------------------------------------------------
  // @ CONSTRUCTOR
  // -----------------------------------------------------------------------------------------------------

  constructor(
    protected sideBarService: SidebarService
  ) {
    super();

    super.addSubscription(
      this.sideBarService
        .expand$
        .subscribe((isExpanded: boolean) => {
          this.isExpanded = isExpanded;
        })
    );
  }

  // -----------------------------------------------------------------------------------------------------
  // @ PUBLIC METHODS
  // -----------------------------------------------------------------------------------------------------

  toggleSideBar(): void {
    this.isExpanded = !this.isExpanded;
    this.sideBarService.toggleSideBar(this.isExpanded);
  }

}
