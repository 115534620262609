<div class="flex flex-row">

  <div class="user-image flex-initial">
    <a [routerLink]="['/profiles/me']">
      <intello-image style="cursor: pointer;" [src]="user?.id" [width]="'35px'" customClass="user-round" [height]="'35px'"
        [loadingClass]="'intello-table__row__column__creator__image-container--loading--35'">
      </intello-image>
    </a>
  </div>
  <!-- user-name -->
  <div class="username ml-4 flex-auto break-words">
    <div class="leading-snug mb-2">
      {{ user?.firstName }} {{ user?.lastName }}
    </div>\\
  </div> <!-- /user-name -->

</div>

<div class="flex flex-row mt-2">

  <!-- additional-actions -->
  <div class="flex-initial ml-1">
    <button class="button" type="button" mat-icon-button [matMenuTriggerFor]="actions">
      <mat-icon class="text-gray-200 icon" svgIcon="arrow_down"></mat-icon>
    </button>
    <mat-menu #actions="matMenu">
      <button mat-menu-item [routerLink]="'/users/' + user.id">{{'Dashboard.Profile' | translate}}</button>
      <button mat-menu-item (click)="logout()">{{'Dashboard.Logout' | translate}}</button>
    </mat-menu>
  </div> <!-- additional-actions -->

  <div class="flex flex-row text-sm flex-auto items-center" style="margin-left: 22px;">
    <ng-container *ngFor="let role of user.roles; trackBy: trackByRole; let last = last">
      {{ RoleHelper.GetExtendedRoleByValueOrNull(role)?.displayName | translate }}<ng-container *ngIf="last !== true">,
      </ng-container>
    </ng-container>
  </div>

</div>