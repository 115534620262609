import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';

import { ButtonsDefaultModule } from '../../../components/buttons/default/buttons-default.module';
import { ButtonsSubmitModule } from '../../../components/buttons/submit/buttons-submit.module';
import { DialogModule } from '../../../components/dialog/dialog.module';
import { SharedModule } from '../../../shared.module';
import { FormDeactivateableDialogComponent } from './form-deactivateable-dialog.component';

@NgModule({
  imports: [
    SharedModule,
    DialogModule,
    MatButtonModule,
    ButtonsSubmitModule,
    ButtonsDefaultModule
  ],
  declarations: [FormDeactivateableDialogComponent],
  exports: [FormDeactivateableDialogComponent]
})
export class FormDeactivateableDialogModule { }
