<!-- LOGO -->

<!-- EXPANDED -->
<ng-container *ngIf="isExpanded === true; else collapsed;">
    <div class="h-16 relative">
        <img src="../../../../../../assets/logo/logo_small.png" class="h-14 w-auto" alt="Intello Logo">
    </div>
</ng-container>

<!-- COLLAPSED -->
<ng-template #collapsed>
    <img src="../../../../../../assets/logo/logo_small.png" class="h-8" alt="Intello Logo">
</ng-template>
