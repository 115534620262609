import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'projects/shared/src/lib/shared/base/components/base-component';
import { LanguageHelper } from 'projects/shared/src/lib/shared/functions/language.helper';
import { ForbiddenService } from 'projects/shared/src/lib/shared/services/local/forbidden.service';
import { NotFoundService } from 'projects/shared/src/lib/shared/services/local/not-found.service';
import { SidebarService } from 'projects/shared/src/lib/shared/services/local/side-bar.service';
import { AppRoute } from '../../../shared/app.route.enum';

@Component({
  selector: 'intello-base-layout',
  templateUrl: './base-layout.component.html',
  styleUrls: ['./base-layout.component.scss']
})
export class BaseLayoutComponent extends BaseComponent implements OnInit {

  // -----------------------------------------------------------------------------------------------------
  // @ INPUT VARIABLES
  // -----------------------------------------------------------------------------------------------------

  isForbidden: boolean = false;

  notFound: boolean = false;

  Languages = LanguageHelper.GetLanguages();

  currentLanguage: any;

  isInput: boolean = false;

  sideBarExpanded: boolean = false;

  showTopBar: boolean = true;

  // -----------------------------------------------------------------------------------------------------
  // @ CONSTRUCTOR
  // -----------------------------------------------------------------------------------------------------

  constructor(
    private forbiddenService: ForbiddenService,
    private notFoundService: NotFoundService,
    private location: Location,
    private sideBarService: SidebarService
  ) {
    super();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ LIFE CYCLE HOOKS
  // -----------------------------------------------------------------------------------------------------

  ngOnInit() {
    super.addSubscription(
      this.forbiddenService
        .isForbidden$
        .subscribe((isForbidden: boolean) => {
          this.isForbidden = isForbidden;
        })
    );

    super.addSubscription(
      this.notFoundService
        .notFound$
        .subscribe((notFound: boolean) => {
          this.notFound = notFound;
          if (notFound === true) {
            this.location.replaceState(AppRoute.Home);
          }
        })
    );

    // SIDE BAR EXPANSION
    this.sideBarExpanded = this.sideBarService.isExpanded;

    super.addSubscription(
      this.sideBarService.expand$.subscribe({
        next: (expanded: boolean) => {
          this.sideBarExpanded = expanded;
        }
      })
    );
  }

  // -----------------------------------------------------------------------------------------------------
  // @ PRIVATE METHODS
  // -----------------------------------------------------------------------------------------------------

}
